import { user } from '../init/client'
import { env } from '../init/env'

export function setupLinkster(): void {
  const emid = new URLSearchParams(window.location.search).get('emid') || ''
  sessionStorage.setItem('emid', emid)
}

export function linksterEvent(data: Record<string, any>): void {
  if (sessionStorage.getItem('emid') === null) return
  const basket = []
  basket.push({
    'amount': 1,
    'attribution': 1,
    'campaign_id': env.VITE_LKS_ID,
    'emid': sessionStorage.getItem('emid'),
    'price': data.value,
    'productname': `${data.box_size}er Box`,
    'token': data.order_id,
    'trigger_id': user.value?.referral_code ? 1 : 2,
  })
  const trackingUrl = 'https://trck.linkster.co/trck/ebasket/?json=' + encodeURIComponent(JSON.stringify(basket)) + '&currency=' + data.currency + '&vc=' + encodeURIComponent(data.coupon)
  const req = new XMLHttpRequest()
  req.withCredentials = true
  req.open('GET', trackingUrl)
  req.send()
}
