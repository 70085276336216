import { watch } from 'vue'
import { user } from '../init/client'
import { env } from '../init/env'
import { locale } from '../init/i18n'
import { log } from '../init/log'
import { campaignData, googleClickId } from './campaign'
import { facebookEvent, loadFacebookTag } from './facebook'
import { taboolaEvent, loadTaboolaTag } from './taboola'
import { GAEvent, GAEvents, GAItem, loadGoogleTag } from './google'
import { privacyTier } from './privacy'
import { loadTikTokTag, tiktokEvent } from './tiktok'
import { loadLinkedinTag } from './linkedin'
import { userCohort } from '../init/state'
import { setupLinkster } from './linkster'

declare global {
  /** Configuration parameters Google Tag */
  interface GAConfigParams {
    /** Automatically send initial page view or not */
    send_page_view?: boolean | undefined
    /** Send groups this configuration target is part of */
    groups?: string | string[] | undefined
  }

  /** Configuration parameters Google Tag */
  interface GAConsentParams {
    /** Enable/disable storage of analyics data */
    analytics_storage?: 'granted' | 'denied'
    /** Enable/disable storage of marketing data */
    ad_user_data?: 'granted' | 'denied'
    ad_personalization?: 'granted' | 'denied'
    /**
     * How long to wait for an update (in seconds).
     *
     * This is only relevant in the initial `default` consent setup.
     */
    wait_for_update?: number;
  }

  interface Window {
    /**
     * The `dataLayer` is an array of `arguments` which will be eventually
     * processed by Google Analytics / Google Tag Manager.
     *
     * While its content is `IArguments`, the actual structure is more like
     * `[ command, ... parameters ]`. See the GA documentation for more info.
     */
    dataLayer?: IArguments[]

    /** Initialize the JavaScript layer of Google Tag (must be called first) */
    gtag?(command: 'js', date: Date): void
    /** Configure Google Tag */
    gtag?(command: 'config', target: string, config?: GAConfigParams): void
    /** Configure the consent settings for Google Tag */
    gtag?(command: 'consent', type: 'default' | 'update', consent: GAConsentParams): void
    /** Set a number of global parameters to be sent with all future events */
    gtag?(command: 'set', parameters: Record<string, any>): void
    /** Set user properties */
    gtag?(command: 'set', type: 'user_properties', parameters: Record<string, any>): void
    /** Inject an event into the data layer to be processed by Google Tag */
    gtag?(command: 'event', name: string, parameters: Record<string, any>): void
  }
}

/** Initialize Braze Asynchronously */
async function initBraze(): Promise<void> {
  const { setupBraze } = await import('./braze')
  setupBraze()
}

/** Initialize support for Analytics, injecting Google Tag's configuration */
export function initializeAnalytics(): void {
  log('Initializing analytics')

  // Basic initialization of the data layer and "gtag" function
  const dataLayer =
    globalThis.window ?
      globalThis.window.dataLayer = globalThis.window.dataLayer || [] :
      []

  const gtag = globalThis.window?.gtag || function gtag() {
    // eslint-disable-next-line prefer-rest-params
    dataLayer.push(arguments)
  }

  // Initial configuration of the Google Tag
  gtag('js', new Date())
  gtag('config', env.VITE_GA_ID)

  // Default consent, and watch for privacy tier changes
  gtag('consent', 'default', {
    analytics_storage: 'granted',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
  })

  // Set country and currency parameters, those won't change
  gtag('set', { country: 'DE', currency: 'EUR' })

  // Set the campaign data / google click ID, if we have it
  if (googleClickId) gtag('set', { gclid: googleClickId })
  if (campaignData) gtag('set', campaignData)

  // Set language, and watch the current locale
  watch(locale, (loc) => gtag('set', { language: loc }), { immediate: true })

  // Set the user uuid, and watch the current user
  watch(user, (user) => gtag('set', { user_id: user?.uuid }), { immediate: true })

  // Watch out for privacy tier changes and load / unload scripts on demand
  watch(privacyTier, async (newTier, oldTier = 0) => {
    if (newTier === 0) return // do nothing until the user selects the tier...

    // First and foremost, _always_ update the consent in the data layer
    gtag('consent', 'update', {
      'analytics_storage': newTier > 1 ? 'granted' : 'denied',
      'ad_user_data': newTier > 2 ? 'granted' : 'denied',
      'ad_personalization': newTier > 2 ? 'granted' : 'denied',
    })

    // If the _new_ tier is less than the _old_ tier, we must reload the page
    // in order to get rid of any <script> tag we might have loaded before.
    // We do this with a delay of 250ms so that anything else that is watching
    // the privacy tier has a chance to complete before the page is reloaded.
    if (newTier < oldTier) return void setTimeout(() => globalThis.window?.location.reload(), 250)

    // Load Google Tag if the privacy tier is at least 2
    if ((newTier >= 2) && (oldTier < 2)) {
      loadGoogleTag(env.VITE_GA_ID)
    }

    // Load Facebook and Tiktok's tags and initialize Braze SDK if the privacy tier is at least 3
    // And submit user cohort
    if ((newTier >= 3) && (oldTier < 3)) {
      gtag('set', 'user_properties', { cohort: userCohort.value })
      loadGoogleTag(env.VITE_GADS_ID) // for ads remarketing
      loadFacebookTag(env.VITE_FBP_ID)
      loadTikTokTag(env.VITE_TTP_ID)
      loadTaboolaTag(env.VITE_TBP_ID)
      loadLinkedinTag(env.VITE_LKP_ID)
      setupLinkster()
      await initBraze()
    }

    // All done!
  }, { immediate: true })
}


/** Convert our internal structure of order lines into GA's `Item[]` */
export function analyticsOrderItems(
    lines?: { ean: string, quantity: number, title: string }[] | undefined,
): GAItem[] {
  return lines ? lines.map((line) => ({
    item_name: line.title,
    item_id: line.ean,
    quantity: line.quantity || 1,
  })) : []
}

/** Add an analytics event to GTAG _data layer_ */
export function analyticsEvent<K extends GAEvent>(event: K, params: GAEvents[K]): void {
  // The `gtag` command _must_ be processed regardless of the current privacy
  // tier: it'll only add things to the `dataLayer`. Then if the tier is greater
  // than 1, the GA script will be loaded and will process the data in there
  log(`%cAnalytics event: ${event}`, 'color: burlywood', params)
  globalThis.window?.gtag?.('event', event, { ...campaignData, ...params })

  if (privacyTier.value > 2) {
    facebookEvent(event, params)
    tiktokEvent(event, params)
    taboolaEvent(event, params, env.VITE_TBP_ID)
  }
}
