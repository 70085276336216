import { ref, watch } from 'vue'

/**
 * Possible values for our cookie tier:
 *
 * * `0`: initial: no privacy tier selected
 * * `1`: essential: no analytics, no marketing
 * * `2`: analytics: only Google Analytics, no marketing
 * * `3`: marketing: both Google Analytics and Marketing
 */
export type PrivacyTier = 0 | 1 | 2 | 3

/* ========================================================================== *
 * Initialization of privacy tier                                             *
 * ========================================================================== */

const tierStorageKey = 'jn:privacy_tier'
const storedTier = globalThis.localStorage?.getItem(tierStorageKey)
let initialTier: PrivacyTier = 0

if (storedTier) {
  try {
    initialTier = parseInt(storedTier) as PrivacyTier
    if (initialTier < 1) initialTier = 0
    else if (initialTier > 2) initialTier = 3
  } catch {
    globalThis.localStorage?.removeItem(tierStorageKey)
  }
}

/** Currently selected user cookie tier (for GTM) */
export const privacyTier = ref(initialTier)

// Watch the privacy tier and save changes in local storage
watch(privacyTier, (tier) => {
  // Store the new privacy tier in local storage
  if (tier) globalThis.localStorage?.setItem(tierStorageKey, tier.toString())
  else globalThis.localStorage?.removeItem(tierStorageKey)
})
