/* ========================================================================== *
 * ENVIRONMENT VARIABLES                                                      *
 * -------------------------------------------------------------------------- *
 * Normalize and export environment variables from ".env...." files.          *
 * ========================================================================== */
/* eslint-disable no-console */

const Environment = {
  // From VITE

  /** [VITE] The base url the app is being served from. */
  BASE_URL: String,
  /** [VITE] The mode the app is running in. */
  MODE: String,
  /** [VITE] Whether the app is running in _development_. */
  DEV: Boolean,
  /** [VITE] Whether the app is running in _production_. */
  PROD: Boolean,
  /** [VITE] Whether the app is running _in the server_. */
  SSR: Boolean,

  // From `.env...` files

  /** The base URL of the API server (e.g. `https://devapi.juit.com/`). */
  VITE_API_URL: String,
  /** The base URL of the application (e.g. `https://www.juit.com/`). */
  VITE_BASE_URL: String,
  /** The Braze api key for web SDK */
  VITE_BRAZE_SDK_KEY: String,
  /** The Stripe api key for Stripe web element */
  VITE_STRIPE_API_KEY: String,
  /** The host name where _Contentful_ data will be accessed from. */
  VITE_CONTENTFUL_HOST: String,
  /** The tokeb used to access _Contentful_ data. */
  VITE_CONTENTFUL_TOKEN: String,
  /** The host name where _Contentful_ preview data will be accessed from. */
  VITE_CONTENTFUL_PREVIEW_HOST: String,
  /** The token used to access _Contentful_ preview data. */
  VITE_CONTENTFUL_PREVIEW_TOKEN: String,
  /** The _Contentful space identifier_ backing the application. */
  VITE_CONTENTFUL_SPACE: String,
  /** The _Contentful environment name_ backing the application. */
  VITE_CONTENTFUL_ENVIRONMENT: String,
  /** The `slug` of the _home page_ in _Contentful_. */
  VITE_CONTENTFUL_HOME_SLUG: String,
  /** The `uuid` of the default delivery location (if not from postcode). */
  VITE_DEFAULT_DELIVERY_LOCATION: String,
  /** The identifier of our _Google Analytics Property_ for analytics. */
  VITE_GA_ID: String,
  /** The identifier of our _Google Remarketing Tag_ for Google Ads. */
  VITE_GADS_ID: String,
  /** The identifier of our _Facebook Pixel_ for analytics. */
  VITE_FBP_ID: String,
  /** The identifier of our _Taboola Pixel_ for analytics. */
  VITE_TBP_ID: String,
  /** The identifier of our _TikTok Pixel_ for analytics. */
  VITE_TTP_ID: String,
  /** The identifier of our _Linkedin Pixel_ for analytics. */
  VITE_LKS_ID: String,
  /** The identifier of our Linkster Campaign for analytics. */
  VITE_LKP_ID: String,
  /** The _version_ to uniquely identify this build in _Sentry_. */
  VITE_VERSION: String,
  /** The API Key for Dixa chat widget. */
  VITE_DIXA_CHAT_KEY: String,
} as const

// Validate all our environment properties
for (const [ key, Ctor ] of Object.entries(Environment)) {
  const type = typeof new Ctor().valueOf()
  const value = import.meta.env[key]

  if ((value === undefined) || (value === null)) {
    console.warn(`Environment "${key}" was not defined`)
  } else if (type !== typeof value) {
    console.warn(`Environment "${key}" expected as ${type} but got ${typeof value}`)
  } else if ((type === 'string') && (! value)) {
    console.warn(`Environment "${key}" defined as empty string`)
  }
}

// Export our environment variables (properly typed)
export const env = import.meta.env as {
  [key in keyof typeof Environment]: ReturnType<InstanceType<typeof Environment[key]>['valueOf']>
}
