import { env } from '../init/env'
import { log } from '../init/log'
import { GAEvent, GAEvents, GAItem, isAnalyticsEvent } from './google'

/* ========================================================================== *
 * LOADING FACEBOOK TAG                                                       *
 * ========================================================================== */

const loadedFacebookTags = new Set<string>()

export function loadFacebookTag(pixelId: string): void {
  if (loadedFacebookTags.has(pixelId)) {
    return log(`Facebook Tag ${pixelId} already loaded`)
  }

  log('Loading Facebook Tag', pixelId)
  const script = document.createElement('script')
  script.text = `
  !function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
        n.callMethod ?
        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
  }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${pixelId}') // initialize our pixel
  fbq('track', 'PageView') // initial page view
  `

  // Append our script to the end of the head
  document.head.appendChild(script)
  loadedFacebookTags.add(pixelId)
}

/* ========================================================================== *
 * FACEBOOK TYPES                                                             *
 * ========================================================================== */

/** Category of the page/product. */
interface FacebookContentCategory {
  content_category: string
}

/** Name of the page/product. */
interface FacebookContentName {
  content_name: string
}

/**
 * Either `product` or `product_group` based on the content_ids or contents
 * being passed.
 *
 * If the IDs being passed in content_ids or contents parameter are IDs of
 * products then the value should be `product`. If product group IDs are being
 * passed, then the value should be `product_group`.
 */
interface FacebookContentType {
  content_type: 'product' | 'product_group'
}

/**
 * Product IDs associated with the event.
 *
 * This is represented either as an array of strings as the `content_ids`
 * property (e.g. ['ABC123', 'XYZ789']), or as an array of JSON objects
 * as the `contents` property that contains the quantity and identifier.
 */
type FacebookContents =
  | { contents: { id: string, quantity: number }[], content_ids?: never }
  | { content_ids: string[] | number[], contents?: never }

/** The value and currency _required_ for the event. */
interface FacebookRequiredCurrencyAndValue {
  currency: string,
  value: number,
}

/** The value and currency _optional_ for the event. */
type FacebookOptionalCurrencyAndValue = FacebookRequiredCurrencyAndValue | {
  currency?: never,
  value?: never
}

/**
 * Used with `InitiateCheckout` event, the number of items when checkout was
 * initiated.
 */
interface FacebookNumItems {
  num_items: number
}

/**
 * Predicted lifetime value of a subscriber as defined by the advertiser and
 * expressed as an exact value.
 */
interface FacebookPredictedLTV {
  predicted_ltv: number
}

/** Used with the Search event. The string entered by the user for search. */
interface FacebookSearch {
  search_string: string
}

/**
 * Used with the `CompleteRegistration` event, to show the status of the
 * registration.
 */
interface FacebookStatus {
  status: boolean
}

/**
 * Interface associating Facebook's _standard_ event names with their payload.
 */
interface FacebookEvents {
  /**
   * When payment information is added in the checkout flow.
   *
   * A person clicks on a save billing information button.
   * content_category, content_ids, contents, currency, value
   */
  AddPaymentInfo:
    & Partial<FacebookContentCategory>
    & Partial<FacebookContents>
    & FacebookOptionalCurrencyAndValue

  /**
   * When a product is added to the shopping cart.
   *
   * A person clicks on an add to cart button.
   */
  AddToCart:
    & Partial<FacebookContentName>
    & FacebookContentType
    & FacebookContents
    & FacebookOptionalCurrencyAndValue

  /**
   * When a product is added to a wishlist.
   *
   * A person clicks on an add to wishlist button.
   */
  AddToWishlist:
    & Partial<FacebookContentCategory>
    & Partial<FacebookContentName>
    & Partial<FacebookContents>
    & FacebookOptionalCurrencyAndValue

  /**
   * When a registration form is completed.
   *
   * A person submits a completed subscription or signup form.
   */
  CompleteRegistration:
    & Partial<FacebookContentName>
    & Partial<FacebookStatus>
    & FacebookOptionalCurrencyAndValue

  /**
   * When a person initiates contact with your business via telephone, SMS,
   * email, chat, etc.
   *
   * A person submits a question about a product.
   */
  Contact: void

  /**
   * When a person customizes a product.
   *
   * A person selects the color of a t-shirt.
   */
  CustomizeProduct: void

  /**
   * When a person donates funds to your organization or cause.
   *
   * A person adds a donation to the Humane Society to their cart.
   */
  Donate: void

  /**
   * When a person searches for a location of your store via a website or
   * app, with an intention to visit the physical location.
   *
   * A person wants to find a specific product in a local store.
   */
  FindLocation: void

  /**
   * When a person enters the checkout flow prior to completing the checkout
   * flow.
   *
   * A person clicks on a checkout button.
   */
  InitiateCheckout:
    & Partial<FacebookContentCategory>
    & Partial<FacebookContents>
    & Partial<FacebookNumItems>
    & FacebookOptionalCurrencyAndValue

  /**
   * When a sign up is completed.
   *
   * A person clicks on pricing.
   */
  Lead:
    & Partial<FacebookContentCategory>
    & Partial<FacebookContentName>
    & FacebookOptionalCurrencyAndValue

  /**
   * When a purchase is made or checkout flow is completed.
   *
   * A person has finished the purchase or checkout flow and lands on thank
   * you or confirmation page.
   */
  Purchase:
    & Partial<FacebookContentName>
    & Partial<FacebookNumItems>
    & FacebookContentType
    & FacebookContents
    & FacebookRequiredCurrencyAndValue

  /**
   * When a person books an appointment to visit one of your locations.
   *
   * A person selects a date and time for a tennis lesson.
   */
  Schedule: void

  /**
   * When a search is made.
   *
   * A person searches for a product on your website.
   */
  Search:
    & Partial<FacebookContentCategory>
    & Partial<FacebookContents>
    & FacebookOptionalCurrencyAndValue
    & FacebookSearch

  /**
   * When a person starts a free trial of a product or service you offer.
   *
   * A person selects a free week of your game.
   */
  StartTrial:
    & Partial<FacebookPredictedLTV>
    & FacebookOptionalCurrencyAndValue

  /**
   * When a person applies for a product, service, or program you offer.
   *
   * A person applies for a credit card, educational program, or job.
   */
  SubmitApplication: void

  /**
   * When a person applies to a start a paid subscription for a product or
   * service you offer.
   *
   * A person subscribes to your streaming service.
   */
  Subscribe:
    & Partial<FacebookPredictedLTV>
    & FacebookOptionalCurrencyAndValue

  /**
   * A visit to a web page you care about (for example, a product page or
   * landing page).
   *
   * ViewContent tells you if someone visits a web page's URL, but not what
   * they see or do on that page.
   *
   * A person lands on a product details page.
   */
  ViewContent:
    & Partial<FacebookContentCategory>
    & Partial<FacebookContentName>
    & FacebookContentType
    & FacebookContents
    & FacebookOptionalCurrencyAndValue
}

declare global {
  interface Window {
    /*
     * The Facebook/Meta pixel interface.
     *
     * Remember, the Facebook/Meta pixel automatically collects page views
     * looking for changes in `window.location` in SPAs.
     *
     * See:
     * * https://developers.facebook.com/docs/meta-pixel/reference
     * * https://developers.facebook.com/docs/meta-pixel/advanced
     */
    fbq?<E extends keyof FacebookEvents>(
      method: 'track',
      event: E,
      params: FacebookEvents[E],
      extra?: { eventID: string },
    ): void
  }
}

/* ========================================================================== *
 * FACEBOOK IMPLEMENTATION                                                    *
 * ========================================================================== */

function randomUUID(): string {
  try {
    return globalThis.crypto.randomUUID()
  } catch {
    const buffer = crypto.getRandomValues(new Uint8Array(16))
    buffer[6] = (buffer[6] & 0x0F) | 0x40
    buffer[8] = (buffer[8] & 0x3F) | 0x80
    const hex = Array.from(buffer).map((n) => n.toString(16).padStart(2, '0'))
    hex.splice(10, 0, '-')
    hex.splice(8, 0, '-')
    hex.splice(6, 0, '-')
    hex.splice(4, 0, '-')
    return hex.join('')
  }
}

function facebookTrack<E extends keyof FacebookEvents>(
    event: E,
    params: FacebookEvents[E],
): void {
  const event_id = randomUUID()

  // track via the pixel...
  globalThis.window.fbq?.('track', event, params, { eventID: event_id })

  // we'll need cookies!
  const cookies = document.cookie.split(';')
      .map((cookie) => cookie.split('='))
      .reduce((cookies, [ key = '', value = '' ]) => {
        cookies[key.trim()] = value.trim()
        return cookies
      }, {} as Record<string, string>)

  // prep the data we need to send to the server
  const serverData = {
    event_name: event,
    event_time: Math.round(Date.now() / 1000),
    user_data: {
      client_user_agent: globalThis.navigator.userAgent,
      fbc: cookies['_fbc'],
      fbp: cookies['_fbp'],
    },
    custom_data: params,
    event_source_url: globalThis.window.location.href,
    event_id: event_id,
    action_source: 'website',
  }

  // wrap our server data in a JSON Blob and send it via the beacon
  const blob = new Blob([ JSON.stringify(serverData) ], { type: 'application/json ' })
  globalThis.navigator.sendBeacon?.(`${env.VITE_API_URL}analytics/v1/facebook`, blob)
}

function facebookContents(items: GAItem[]): { id: string, quantity: number }[] {
  return items.map((item) => ({
    id: item.item_id,
    quantity: item.quantity,
  }))
}


export function facebookEvent<K extends GAEvent>(event: K, params: GAEvents[K]): void {
  // The facebook pixel automatically tracks `page_view`. All other event types
  // are mapped as follows:
  //
  // * add_payment_info  => AddPaymentInfo
  // * add_to_cart       => AddToCart
  // * begin_checkout    => InitiateCheckout
  // * purchase          => Purchase
  // * sign_up           => CompleteRegistration
  // * view_item         => ViewContent

  if (isAnalyticsEvent('add_payment_info', event, params)) {
    return facebookTrack('AddPaymentInfo', {})
  }

  if (isAnalyticsEvent('add_to_cart', event, params)) {
    return facebookTrack('AddToCart', {
      contents: facebookContents(params.items),
      content_type: 'product',
    })
  }

  if (isAnalyticsEvent('begin_checkout', event, params)) {
    return facebookTrack('InitiateCheckout', {
      contents: facebookContents(params.items),
      currency: params.currency || 'EUR',
      value: params.value || 0,
    })
  }

  if (isAnalyticsEvent('purchase', event, params)) {
    return facebookTrack('Purchase', {
      contents: facebookContents(params.items),
      currency: params.currency || 'EUR',
      value: params.value || 0,
      content_type: 'product',
    })
  }

  if (isAnalyticsEvent('sign_up', event, params)) {
    return facebookTrack('CompleteRegistration', {
      status: true,
    })
  }

  if (isAnalyticsEvent('view_item', event, params)) {
    return facebookTrack('ViewContent', {
      contents: facebookContents(params.items),
      content_type: 'product',
    })
  }
}
