import { log } from '../init/log'

/* ========================================================================== *
 * LOADING GOOGLE TAG                                                         *
 * ========================================================================== */

const loadedGoogleTags = new Set<string>()

/** Load GA tracking */
export function loadGoogleTag(streamId: string): void {
  if (loadedGoogleTags.has(streamId)) {
    return log(`Google Tag ${streamId} already loaded`)
  }

  log('Loading Google Tag', streamId)
  const script = document.createElement('script')
  script.src = `https://www.googletagmanager.com/gtag/js?id=${streamId}`
  script.async = true

  // Append our script to the end of the head
  document.head.appendChild(script)
  loadedGoogleTags.add(streamId)
}

/* ========================================================================== *
 * GOOGLE TAG TYPES                                                           *
 * ========================================================================== */

/** A type describing an _item_ associated with an event */
export interface GAItem {
  item_id: string
  item_name: string
  quantity: number
  category?: string | undefined
  price?: number
}

/** A type indicating the monetary amount of an event */
type GACurrencyAndValue =
    | { currency: string, value: number }
    | { currency?: never, value?: never }

/** A type indicating a _coupon_ associated with an event */
interface GACoupon {
  coupon?: string,
}

/** A type indicating the _items_ associated with an event */
interface GAItems {
  items: GAItem[]
}

/* == CUSTOM PARAMETERS ==================================================== */

/** A type indicating the _user UUID_ associated with an event (custom) */
interface JuitUserId {
  user_id: string
}

/** A type associating an event with a _box_ of `box_items` size (custom) */
interface JuitBox {
  box_size: number | string
}

/* == CUSTOM EVENTS KEYS SORTED FOR MAPPED TYPES ============================ */

/** Triggered when a user on Thank You page... **/
type RatingEvents =
  /** ...clicks on a star **/
  'rating_1' | 'rating_2' | 'rating_3' | 'rating_4' | 'rating_5' |
  /** ...writes a comment **/
  'rating_comment' |
  /** ...submits a review **/
  'rating_submit'

/** Triggered when the box... **/
type BoxEvents =
  /** ...has been selected */
  'select_box' |
  /** ...size has been downgraded/upgraded **/
  'box_downgrade' | 'box_upgrade' |
  /** ...size upgrade has been accepted/declined **/
  'box_upgrade_accept' | 'box_upgrade_decline' |
  /** ...upgrade is presented to a customer **/
  'box_upgrade_view'

/** Triggered when a customer has submitted a coupon **/
type CouponEvents = 'coupon_invalid' | 'coupon_added'

/** Triggered when more/less user reviews are shown */
type ShowReviewEvents = 'reviews_showmore' | 'reviews_showless'


/* ========================================================================== *
 * GA EVENT TYPES                                                             *
 * ========================================================================== */
/**
 * Google Analytics 4 Event Types. Here we define _only_ the events we are
 * using. Preferrably, for all events, we should use the _standard_ GA4 events,
 * and us _custom_ events only when really necessary.
 *
 * See:
 * * https://support.google.com/analytics/answer/9267735?hl=en
 * * https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 */
export type GAEvents = {
  /* == BASIC EVENTS ======================================================== */
  /** See: https://support.google.com/analytics/answer/11403294?hl=en */
  'page_view': {
    page_title?: string | undefined
    page_location: string
  }

  /* == STANDARD GA EVENTS ================================================== */
  /**
   * Triggered when a customer has selected his payment method.
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_payment_info
   */
  'add_payment_info': GACurrencyAndValue & GAItems & GACoupon & {
    payment_type?: string | undefined
  }

  /**
   * Triggered when a customer has added his shipping info (address).
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_shipping_info
   */
  'add_shipping_info': GACurrencyAndValue & GAItems & GACoupon & {
    shipping_tier?: string | undefined
  }

  /**
   * Triggered when a customer has added a product to his cart.
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_to_cart
   */
  'add_to_cart': GACurrencyAndValue & GAItems

  /**
   * Triggered when a customer has completed his order, and is proceeding to
   * checkout (selection of delivery address, slot, payment method, ...)
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#begin_checkout
   */
  'begin_checkout': GACurrencyAndValue & GAItems & GACoupon

  /**
   * Triggered when a user has proactively logged in (IOW, when the user comes
   * from an automatic login via access/refresh tokens, this is not triggered).
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#login
   */
  'login': JuitUserId & {
    method?: string
  }

  /**
   * Triggered when a user has completed a purchase (on the thank you page,
   * after Stripe reports a successful payment)
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase
   */
  'purchase': GACurrencyAndValue & GAItems & GACoupon & JuitBox & {
    transaction_id: string
    affiliation?: string | undefined
    shipping?: number | undefined
    tax?: number | undefined
    order_id?: string
  }

  /**
   * Triggered when a customer has removed a product from his cart.
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#remove_from_cart
   */
  'remove_from_cart': GACurrencyAndValue & GAItems

  /**
   * Triggered when a customer has shared something (e.g. his referral code).
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#share
   */
  'share': {
    method?: string | undefined
    item_id: string
    content_type: string
  }

  /**
   * Triggered when a _new_ user has successfully created an account.
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#sign_up
   */
  'sign_up': JuitUserId & {
    method?: string | undefined
  }

  /**
   * Triggered when a user clicks to see his cart.
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_cart
   */
  'view_cart': GACurrencyAndValue & GAItems

  /**
   * Triggered when a user has viewed an item (e.g. a dish modal in the order page).
   *
   * See: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item
   */
  'view_item': GACurrencyAndValue & GAItems

  /* == CUSTOM EVENTS ======================================================= */
  /** Triggered when the FE attempts to automatically login via refresh token */
  'initial_login': {
    result: 'no_token' | 'success' | 'invalid_token'
  }

  /** Triggered when the registration modal pops up, asking to sign up */
  'begin_sign_up': {}

  /** Triggered when a customer checks out an order (gets redirected to pay) */
  'checkout': GACurrencyAndValue & GAItems & GACoupon & JuitBox

  /** Triggered when a customer is prompted to either log in or sign up */
  'login_or_sign_up': {}

  /** Triggered when a user filters the reviews */
  'reviews_sorting': {
    category: string
  }

  /** Triggered when a customer has selected a delivery slot */
  'select_delivery_window': {
    delivery_date: string
    delivery_slot: string
  }

  /** Triggered when a customer selects a custom dish filter */
  'select_filter': {
    tag_name: string
  }

  /** Triggered when a customer selects a nutrition goal */
  'choose_preference': {
    preference: string
  }

  /** Triggered when a customer add or remove a dish as favorite */
  'add_favourite': {
    item: GAItem
  }
  'remove_favourite': {
    item: GAItem
  }

  /** Triggered when a customer is looking for more/less filter options */
  'filters_showmore': {}
  'filters_showless': {}

  /** Triggered when a customer is prompted to select a delivery slot */
  'view_delivery_window': {}

  /** Triggered when a customer is prompted to select his delivery address */
  'view_shipping_info': {}

  /** Triggered when B2B form is submitted */
  'lead': {}

/* == MAPPED CUSTOM EVENTS TYPES ==================================================== */
} & {
  /** Box related **/
  [ key in BoxEvents ]: JuitBox

} & {
  /** Coupon related **/
  [ key in CouponEvents ]: Required<GACoupon>

} & {
  /** User review related **/
  [ key in ShowReviewEvents ]: {
    cards_shown: number
  }

} & {
  /** Thankyou page rating related **/
  [ key in RatingEvents ]: {
    user_id: string,
    order_id: string
  }
}

/** All recognized event types for GA */
export type GAEvent = keyof GAEvents

/* ========================================================================== *
 * UTILITY METHODS                                                            *
 * ========================================================================== */

/**
 * A type guard helping to resolve the type of the parameters for an event,
 * giving its key.
 *
 * Basically, this simply compares that `event` is equal to `key` and asserts
 * the type of `params` as `GaTypes[key]`. Usage:
 *
 * ```ts
 * function foo<K extends GAEvent>(event: K, params: GAEvents[K]) {
 *   if (isAnalyticsEvent('page_view', event, params)) {
 *     // ... here `params` will have type `GAEvents['pageview']`
 *     const page_location = params['page_location'] // this works!
 *   }
 * }
 * ```
 */
export function isAnalyticsEvent<E1 extends GAEvent, E2 extends GAEvent>(
    event: E1,
    key: E2,
    params: GAEvents[E2],
): params is GAEvents[E1] & GAEvents[E2] {
  void params
  return event as GAEvent === key as GAEvent
}
