<!--==================================================================+
| MODAL COMPONENT: REGISTER                                           |
+===================================================================-->

<!-- eslint-disable vue/no-v-html -->

<template>
  <div class="text-center register">
    <h2>{{ $t( 'authentication.welcome-register' ) }}</h2>

    <!-- REGISTER FORM -->
    <form id="register-user" class="mt-4 flex flex-col" @submit.stop.prevent="registerUser">
      <div class="flex mb-2">
        <!-- Given Name -->
        <input-text
          v-model:text="given_name"
          v-model:valid="given_name_valid"
          class="mr-2"
          :autocomplete="'given-name'"
          :placeholder="$t( 'order.first_name' )"
          :requirement="true"
        />

        <!-- Family Name -->
        <input-text
          v-model:text="family_name"
          v-model:valid="family_name_valid"
          :autocomplete="'family-name'"
          :placeholder="$t( 'order.last_name' )"
          :requirement="true"
        />
      </div>

      <!-- Email -->
      <input-email v-model:email="email" v-model:valid="email_valid" />
      <p v-if="email_used" class="invalid-msg">
        {{ $t( 'authentication.acc-exist' ) }}
      </p>
      <p v-if="server_error" class="invalid-msg">
        {{ $t( 'authentication.server-error-try-again' ) }}
      </p>

      <!-- Password -->
      <input-password v-model:password="password" v-model:valid="password_valid" />

      <!-- Legal Text -->
      <div class="flex text-xs mt-3 md:text-sm text-left mobile-sm:flex-wrap">
        <p class="mt-0.5" v-html="$t('authentication.accept_01')" />&nbsp;
        <p class="mt-0.5" v-html="$t('authentication.accept_02')" />
      </div>
      <p class="mb-2 mt-0.5 text-xs md:text-sm text-left">
        {{ $t( 'authentication.newsletter-alert' ) }}
      </p>

      <!-- Button -->
      <juit-button
        class="w-full mt-3 small"
        :class="{ small: routerback }"
        type="submit"
        form="register-user"
        :disabled="!form_valid"
      >
        {{ $t( 'authentication.register' ) }}
      </juit-button>
    </form>

    <!-- BACK (CHANGE SCREEN) -->
    <div class="juit-link w-full py-3 -mb-3" @click="$emit('screen')">
      {{ $t( 'authentication.back' ) }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { log } from '../../init/log'
  import { client } from '../../init/client'
  import juitButton from '../../widgets/juit-button.vue'
  import { analyticsEvent } from '../../analytics'
  import inputText from '../../widgets/input-text.vue'

  export default defineComponent({
    name: 'JuitRegisterScreen',
    components: { juitButton, inputText },
    props: {
      routerback: {
        type: Boolean,
        reqired: false,
        default: true,
      },
    },
    emits: {
      loading: (confirm: boolean) => true,
      screen: () => true,
    },
    data: () => ({
      server_error: false,
      email_used: false,
      given_name: '',
      family_name: '',
      email: '',
      password: '',
      given_name_valid: false,
      family_name_valid: false,
      email_valid: false,
      password_valid: false,
    }),
    computed: {
      form_valid(): boolean {
        return this.given_name_valid && this.family_name_valid && this.email_valid && this.password_valid
      },
    },
    methods: {
      /* ==================================================================== *
       * Register user                                                        *
       * -------------------------------------------------------------------- */
      registerUser() {
        this.$emit('loading', true)
        this.email_used = false
        this.server_error = false

        client.registerUser({
          given_name: this.given_name,
          family_name: this.family_name,
          email: this.email,
          password: this.password,
          locale: this.$i18n.locale as 'en' | 'de',
        }).then((res) => {
          this.$emit('loading', false)
          if (!res) this.email_used = true
          else {
            // Push register event to datalayer
            analyticsEvent('sign_up', {
              method: 'email',
              user_id: res.uuid,
            })
            if (this.routerback) window.history.go(-1)
          }
        }).catch((e) => {
          this.server_error = true
          this.$emit('loading', false)
          log(e)
        })
      },
    },
  })
</script>
