<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="summary">
    <!--==================================================================+
    | ADDRESS                                                             |
    +===================================================================-->
    <div class="info address" @click="$emit('step', 'addresses')">
      <div class="header">
        <truck class="w-7 ml-0.5 mr-2.5 self-center flex-shrink-0" />
        <span>{{ $t('order.shipping_address') }}: <br class="sm:hidden">
          <span class="scnd-line">
            {{ checkout.addresses.shipping?.street_name }}
            {{ checkout.addresses.shipping?.house_number }},
            {{ checkout.addresses.shipping?.postal_code }}
            {{ checkout.addresses.shipping?.city }}</span>
        </span>
        <p v-if="invalid_username" class="invalid-msg">
          {{ $t('authentication.correct-data') }}
        </p>
        <edit class="edit" />
      </div>
    </div>

    <!--==================================================================+
    | DELIVERY SLOTS                                                      |
    +===================================================================-->
    <div
      class="info slot white-carousel-bg"
      :class="[{
        active: accordion_active === 'slots' || (!checkout.slot && !nordfrost) || slot_error || (nordfrost && !checkout.nordfrost_week),
        with_urbify: urbify_frozen,
        with_error: slot_error,
        nordfrost,
      }, scrollY]"
    >
      <div class="header" @click="accordion_active = 'slots'">
        <clock class="w-6 ml-1 mr-3 self-center flex-shrink-0" />
        <span v-if="!nordfrost">{{ $t('pre-checkout.delivery-summary', { size: cart.box }) }}: <br class="sm:hidden">
          <span class="scnd-line">{{ $getDate(checkout.slot?.date) }}, {{ checkout.slot?.slot }}</span>
        </span>
        <span v-else-if="checkout.nordfrost_week?.cw">{{ $t('pre-checkout.nordfrost-delivery-info', { size: cart.box })
                                                      }}&nbsp;<br class="sm:hidden">
          <span class="scnd-line">CW{{ checkout.nordfrost_week.cw }} ({{
            $getDate(checkout.nordfrost_week.firstDay.toString()) }} – {{
            $getDate(checkout.nordfrost_week.lastDay.toString()) }})</span>
        </span>
        <div class="carrier">
          <dpd-logo v-if="checkout.slot?.name.startsWith('DPD')" />
          <urbify-logo v-if="checkout.slot?.name.startsWith('Urbify')" />
          <dhl-logo v-if="checkout.slot?.name.startsWith('DHL')" />
        </div>
        <edit class="edit" />
      </div>
      <juit-delivery-slots
        :error="slot_error"
        :b2b="b2b"
        :nordfrost="nordfrost"
        @active="accordion_active = ''"
        @error="slot_error = true"
        @urbify-frozen="urbify_frozen = $event"
        @scroll-y="scrollY = $event"
      />
      <div
        v-if="((accordion_active === 'slots' || !checkout.slot || slot_error) && urbify_frozen) ||
          ((accordion_active === 'slots' || !checkout.nordfrost_week) && nordfrost)"
        class="delivery-extra-msg"
        :class="{ nordfrost }"
      >
        <div class="msg-box">
          <info v-if="nordfrost" class="info-icon" />
          <urbify-frozen-leaf v-else class="urbify-leaf" />
          <p v-html="$t(nordfrost ? 'checkout.nordfrost-delivery-msg' : 'checkout.urbify-msg')" />
        </div>
      </div>
    </div>

    <!--==================================================================+
    | PAYMENT                                                             |
    +===================================================================-->
    <div
      class="info payment text-black"
      :class="[{ active: accordion_active === 'payment' || !selected_payment }, { nordfrost }]"
    >
      <div
        class="header"
        @click="accordion_active = !nordfrost ? 'payment' : accordion_active"
      >
        <euro class="w-6 ml-1 mr-3 self-center flex-shrink-0" />
        <span class="self-center">
          <span v-if="!nordfrost" class="scnd-line">{{ $t(`checkout.${selected_payment === 'invoice' ?
                                                      'nordfrost-payment-info' : selected_payment || 'choose-payment'}`) }}
            <span v-if="selected_payment === 'creditcard' && !selected_mandate" class="inline-block">/&thinsp;Google
              Pay</span><span v-if="selected_payment === 'creditcard' && !selected_mandate">&thinsp;/&thinsp;Apple
              Pay</span>
            <span v-if="selected_mandate">({{ selected_mandate_info }})</span>
          </span>
          <span v-else class="scnd-line">{{ $t('checkout.nordfrost-payment-info') }}</span>
        </span>
        <edit v-if="!nordfrost" class="edit -mt-0.5 sm:mt-0" />
      </div>
      <juit-payment-methods
        v-if="!nordfrost"
        ref="payment_methods"
        :mandates="mandates_sorted"
        :default-mandate="default_mandate"
        @active="accordion_active = ''"
        @selected="updatePayment"
      />
    </div>

    <!--==================================================================+
    | VOUCHER                                                             |
    +===================================================================-->
    <div v-if="!b2b" class="info voucher text-black" :class="{ active: accordion_active === 'voucher' }">
      <div class="header" @click="showVoucherInput()">
        <voucher class="w-6 ml-1 sm:-mt-0.5 sm:-mb-1 mr-3 self-center flex-shrink-0" />
        <span class="relative flex w-full sm:w-auto">
          <input
            v-model="voucher"
            class="voucher-input"
            :class="{ active: accordion_active === 'voucher' }"
            type="text"
            @input="voucher_valid = false"
          >
          <p v-if="voucher_valid" class="valid-voucher scnd-line">{{ voucher.toUpperCase() }}</p>
          <p v-else class="title">{{ $t('order.voucher') }}</p>
          <div v-if="voucher_valid" class="check" />
          <juit-button
            v-if="accordion_active === 'voucher'"
            class="small"
            @click="preOrder()"
          >
            {{ $t('checkout.redeem-voucher') }}
          </juit-button>
        </span>
        <edit v-if="accordion_active !== 'voucher'" class="edit -mt-0.5 sm:mt-0" />
      </div>
      <p v-if="voucher_error && accordion_active === 'voucher'" class="invalid-msg">
        {{ $t(`order.voucher_${voucher_error}`) }}
      </p>
    </div>

    <!--==================================================================+
    | SUMMARY                                                             |
    +===================================================================-->
    <div v-if="pre_order && (checkout.slot?.uuid || nordfrost)" class="cart">
      <div
        class="summary-line detail headline"
        :class="`box-${pre_order.summary.quantity}`"
        @click="cart_details = !cart_details"
      >
        <box-icon class="-mt-0.5 w-7 pl-0.5 mr-3 self-center flex-shrink-0" />
        <span>
          <span class="font-semibold">{{ $t(
            `boxes.${cart.box}erbox-name`).startsWith('[') && $t(`boxes.${cart.box}erbox-name`).endsWith(']') ?
            nordfrost ? $t('boxes.sum-dishes-text', { sum: cart.total }) :
            `${cart.box}er Box` : $t(`boxes.${cart.box}erbox-name`)
          }}</span>
          <arrow class="arrow" :class="{ active: cart_details }" />
          <span class="ml-3">{{ $t(cart_details ? 'checkout.hide-cart-details' : 'checkout.show-cart-details')
          }}</span>
        </span>
        <span>{{ $currency(pre_order.summary.subtotal) }}</span>
      </div>
      <div class="summary-line no-scrollbar dish-list" :class="{ active: cart_details }">
        <div v-for="line in pre_order.lines" :key="line.name" class="detail w-full flex">
          <div v-if="line.type === 'physical'" class="dish">
            <span class="ml-0.5 w-6 mr-4 text-center">{{ line.quantity }}</span>
            <span>{{ getProductTitle(line.ean) }}</span><span v-if="line.metadata?.special_tag">&nbsp;({{
              `${line.metadata.special_tag}` }}<span v-if="line.metadata.extra">{{ ` +${$currency(b2b ?
              line.metadata?.extra_net : line.metadata.extra)}` }}</span>)</span>
          </div>
        </div>
      </div>
      <div class="summary-line detail short">
        <span>{{ $t('order.shipping_cost') }}</span>
        <span>{{ $currency(pre_order.summary.shipping_fee) }}</span>
      </div>
      <div v-if="discounts.length" class="summary-line short text-green">
        <div v-for="discount in discounts" :key="discount.uuid" class="detail">
          <span>{{ discount.metadata?.free_shipping ? $t('order.free-shipping') : discount.name }}</span>
          <span v-if="discount.metadata?.free_shipping && 'shipping_total' in discount">{{
            $currency(discount.shipping_total as number) }}</span>
          <span v-else-if="discount.type === 'extra_dish'">{{ $currency(0) }}</span>
          <span v-else-if="'base_total' in discount">{{ $currency(discount.base_total as number) }}</span>
          <span v-else-if="discount.unit_price">{{ $currency(discount.unit_price) }}</span>
        </div>
      </div>
      <div class="summary-line short">
        <div class="detail">
          <span>{{ $t('order.nettotal') }}</span>
          <span>{{ $currency(pre_order.summary.total_net) }}</span>
        </div>
        <div class="detail">
          <span>{{ $t('order.vat') }}</span>
          <span>{{ $currency(pre_order.summary.vat) }}</span>
        </div>
      </div>
      <div class="summary-line detail total short">
        <span>{{ $t('order.pricetotal') }}</span>
        <span>{{ $currency(pre_order.summary.total) }}</span>
      </div>

      <div class="summary-line detail flex-wrap short">
        <div v-if="!nordfrost" class="payment-icons">
          <payments-visa />
          <payments-master />
          <payments-amex />
          <payments-paypal />
          <payments-gpay />
          <payments-apple />
          <payments-sepa v-if="b2b" />
          <payments-klarna />
        </div>
        <span class="agb-alert" :class="{ '!mt-0': nordfrost }" v-html="$t('checkout.agb-alert')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { addBreadcrumb, captureException } from '@sentry/browser'
  import alertModal from '../../modals/alert-modal.vue'
  import juitDeliverySlots from '../../widgets/juit-delivery-slots.vue'
  import juitPaymentMethods from './payment-methods.vue'
  import { Dish, fetchAllProducts } from '../../content'
  import type { DiscountLine, ExtraDishLine, PreorderResponse, Utm } from '@juitnow/api-orders-v2'
  import { Error } from '@juitnow/lib-schema/types'
  import { analyticsEvent, analyticsOrderItems } from '../../analytics'
  import { campaignData } from '../../analytics/campaign'
  import { checkoutBusy, userMandates } from '../../init/state'
  import { client, user } from '../../init/client'
  import { defineComponent, watch } from 'vue'
  import { env } from '../../init/env'
  import { initializeInventories } from '../../init/inventories'
  import { locale } from '../../init/i18n'
  import { logError } from '../../init/log'
  import { reactiveCheckout, reactiveCart, ReactiveCart } from '../../init/reactive-order'
  import { Mandate, Method, OneOffMandatePaymentOption, OneOffPaymentOption } from '@juitnow/api-payments'

  import {
    arrow as arrow,
    box as boxIcon,
    carriers_logo_dpd as dpdLogo,
    carriers_logo_dhl as dhlLogo,
    carriers_logo_urbify as urbifyLogo,
    carriers_leaf as urbifyFrozenLeaf,
    checkout_clock as clock,
    checkout_delivery as truck,
    checkout_edit as edit,
    checkout_euro as euro,
    checkout_voucher as voucher,
    checkout_info as info,
    payment_amex as paymentsAmex,
    payment_sepa2 as paymentsSepa,
    payment_gpay as paymentsGpay,
    payment_apple as paymentsApple,
    payment_klarna2 as paymentsKlarna,
    payment_master as paymentsMaster,
    payment_ppal as paymentsPaypal,
    payment_visa as paymentsVisa,
  } from '../../assets/async'
  import { StripeError } from '@stripe/stripe-js'

  export default defineComponent({
    components: {
      juitDeliverySlots,
      juitPaymentMethods,
      arrow,
      clock,
      euro,
      truck,
      voucher,
      edit,
      info,
      dpdLogo,
      dhlLogo,
      urbifyLogo,
      boxIcon,
      paymentsAmex,
      paymentsGpay,
      paymentsApple,
      paymentsSepa,
      paymentsMaster,
      paymentsPaypal,
      paymentsVisa,
      paymentsKlarna,
      urbifyFrozenLeaf,
    },
    props: {
      b2b: {
        type: Boolean,
        default: false,
      },
      nordfrost: {
        type: Boolean,
        default: false,
      },
    },

    emits: [ 'step', 'stripe' ],

    data: () => ({
      busy: false,
      accordion_active: '',
      products: [] as Dish[],
      pre_order: undefined as PreorderResponse | undefined,
      voucher_validation: undefined as PreorderResponse | undefined,
      voucher: '',
      voucher_valid: false as boolean,
      voucher_error: undefined as undefined | string,
      error_reason: [] as string[],
      slot_error: false,
      cart_details: false,
      selected_payment: undefined as Method | undefined,
      selected_mandate: undefined as Mandate | undefined,
      selected_mandate_info: undefined as string | undefined,
      urbify_frozen: false,
      scrollY: 'at_start',
      invalid_username: false,
    }),

    computed: {
      cart(): ReactiveCart {
        return reactiveCart
      },
      default_mandate() {
        const defaultMandate = userMandates.value.find((mandate) => mandate.default)
        return defaultMandate?.method !== 'sepa_debit' || this.b2b ? defaultMandate : undefined
      },
      mandates_sorted(): Record<string, Mandate[]> | undefined {
        if (!userMandates.value.length) return undefined
        const methods = [] as Method[]
        userMandates.value.forEach((mandate) => {
          if (!methods.includes(mandate.method)) methods.push(mandate.method)
        })
        const sorted_mandates = {} as Record<Method, Mandate[]>
        methods.forEach((method) => {
          sorted_mandates[method] = userMandates.value.filter((mandates) => mandates.method === method)
        })
        return sorted_mandates
      },
      checkout() {
        return reactiveCheckout
      },
      discounts() {
        if (!this.pre_order) return []
        const discounts = [] as Array<DiscountLine | ExtraDishLine>
        this.pre_order.lines.filter((line) => (line.type === 'discount') || (line.type === 'extra_dish')).forEach((line) => {
          if ('shipping_total' in line && 'base_total' in line) {
            discounts.push({ ...line as DiscountLine, metadata: { free_shipping: true } })
            if (line.base_total) discounts.push(line as DiscountLine)
          } else discounts.push(line as DiscountLine | ExtraDishLine)
        })
        return discounts
      },

      /* ========================================================================== *
       * Make a preorder on user/slot/address change                                *
       * -------------------------------------------------------------------------- */
      call_pre_order() {
        return [ this.checkout.slot?.uuid, this.checkout.addresses.shipping?.uuid, user.value?.uuid ]
      },
    },

    watch: {
      call_pre_order: {
        handler() {
          if (this.checkout.slot?.uuid && this.checkout.addresses.shipping?.uuid && !this.busy && this.user) this.preOrder()
        },
        deep: true,
        immediate: true,
      },

      // GA Event: View the delivery slots
      accordion_active(acc) {
        if (acc === 'slots') {
          analyticsEvent('view_delivery_window', {})
        }
      },
    },

    mounted() {
      /* ========================================================================== *
       * Get details of products in cart on local change                            *
       * -------------------------------------------------------------------------- */
      watch(locale, (locale) => {
        if (!this.cart.dishes) return
        const slugs = this.cart.dishes.map((dish) => dish.slug)
        if (slugs.length) {
          fetchAllProducts(locale).then((products) => {
            if (products) this.products = products.filter((product) => slugs.includes(product.slug))
          }).catch((error) => logError(`Error fetching products (locale="${locale}")`, error))
        }
      }, { immediate: true })

      /* ========================================================================== *
       * Prefill voucher info as soon as opening the summary                        *
       * -------------------------------------------------------------------------- */
      this.preOrder()

      /* ========================================================================== *
       * Check if the username is valid                                             *
       * -------------------------------------------------------------------------- */
      if (this.user && (this.checkUsername(this.user.given_name) || this.checkUsername(this.user.family_name))) this.invalid_username = true
    },

    methods: {
      /* ========================================================================== *
       * Check if the username is empty or has non-latin characters                 *
       * -------------------------------------------------------------------------- */
      checkUsername(name: string) {
        if (name === '') return true
        else if (/[^0-9&'\-.,\u0020\u0041-\u005a\u0061-\u007a\u00c0-\u00ff]/.test(name)) return true
        else return false
      },

      /* ========================================================================== *
       * Get product title                                                          *
       * -------------------------------------------------------------------------- */
      getProductTitle(ean: string | undefined) {
        return this.cart.dishes.find((dish) => dish.ean === ean)?.title
      },

      /* ========================================================================== *
       * Show voucher input and focus it                                            *
       * -------------------------------------------------------------------------- */
      showVoucherInput() {
        this.accordion_active = 'voucher'
        const input: HTMLInputElement | null = document.querySelector('.voucher-input')
        if (input) input.focus()
      },

      async updatePayment(payment: Method, mandate: Mandate | undefined, info: string | undefined) {
        this.selected_payment = payment
        this.selected_mandate = mandate
        this.selected_mandate_info = info
        this.$emit('stripe', payment === 'applepay' && this.pre_order?.summary.total)
      },

      /* ========================================================================== *
       * Pre-Order                                                                  *
       * -------------------------------------------------------------------------- */
      async preOrder() {
        this.busy = true
        checkoutBusy.value.push('')
        this.voucher =
          this.voucher.toUpperCase() || // previously saved in this component
          this.cart.prefilled_cart_voucher?.toUpperCase() || // from prefilled cart
          localStorage.getItem('referral_code')?.toUpperCase() || // from referral code in URL
          '' // no voucher code

        this.voucher_error = undefined
        this.voucher_valid = false
        this.slot_error = false
        try {
          this.pre_order = await client.ordersV2.preorder({
            location_uuid: this.checkout.slot?.location_uuid || env.VITE_DEFAULT_DELIVERY_LOCATION as string & { __uuid: never; },
            lines: this.cart.dishes.map((dish) => {
              return {
                ean: dish.ean,
                quantity: dish.quantity,
              }
            }),
            user_uuid: user.value?.uuid,
            slot_uuid: this.nordfrost ? undefined : this.checkout.slot?.uuid,
            shipping_address_uuid: this.checkout.addresses.shipping?.uuid as string & { __uuid: never; },
            voucher_code: this.b2b ? '' : this.voucher,
            locale: locale.value,
          })
          reactiveCheckout.total = this.pre_order?.summary.total
          if (this.pre_order?.summary.total === 0 && user.value?.uuid) {
            (this.$refs.payment_methods as InstanceType<typeof juitPaymentMethods>).selectPayment('creditcard')
            this.updatePayment('creditcard', undefined, undefined)
          }
          if (this.pre_order?.errors?.length) this.voucher_error = this.pre_order.errors.find((err) => err.subject === 'voucher')?.reason
          this.voucher_valid = Boolean(this.voucher && !this.voucher_error)
          if (this.voucher_valid) this.accordion_active = ''

          // Remove invalid voucher from local storage if the user is logged in
          if (this.voucher_error && user.value?.uuid) localStorage.removeItem('referral_code')

          // GA Event: Voucher
          if (this.voucher) {
            const event = this.voucher_error ? 'coupon_invalid' : 'coupon_added'
            analyticsEvent(event, { 'coupon': this.voucher.toUpperCase() })
          }
        } catch (err) {
          this.resolveError(err as Error)
        } finally {
          checkoutBusy.value.pop()
          if (this.nordfrost) localStorage?.setItem('jn:nordfrost-order', JSON.stringify(this.pre_order))
          this.busy = false
        }
      },

      /* ========================================================================== *
       * Create New Order                                                           *
       * -------------------------------------------------------------------------- */
      async submitOrder(stripe?: boolean) {
        this.slot_error = false

        if (this.checkout.slot?.uuid || (this.nordfrost && this.checkout.nordfrost_week?.cw)) {
          checkoutBusy.value.push('')

          const utm: Utm = {
            'id': campaignData?.campaign_id,
            'source': campaignData?.campaign_source,
            'medium': campaignData?.campaign_medium,
            'campaign': campaignData?.campaign_name,
            'term': campaignData?.campaign_term,
            'content': campaignData?.campaign_content,
          }

          const order = {
            lines: this.cart.dishes.map((dish) => {
              return { ean: dish.ean, quantity: dish.quantity }
            }),
            location_uuid: this.checkout.slot?.location_uuid || env.VITE_DEFAULT_DELIVERY_LOCATION,
            locale: locale.value,
            billing_address_uuid: this.checkout.addresses.billing!.uuid,
            shipping_address_uuid: this.checkout.addresses.shipping!.uuid,
            voucher_code: this.b2b ? '' : this.voucher,
            metadata: { utm } as Record<string, any>,
          }

          // Handle metadata for Compado
          const compado = localStorage.getItem('jn:compado')
          if (compado) {
            try {
              const fifteen_days = 1000 * 60 * 60 * 24 * 15
              const data: CompadoData = JSON.parse(compado)
              const { clickid, timestamp } = data

              if ((Date.now() - timestamp) < fifteen_days) {
                order.metadata.compado_clickid = clickid
              }
            } catch (error) {
              // Send over any error to Sentry for reporting...
              captureException(error, { level: 'error' })
              addBreadcrumb({ message: 'Error setting Compado metadata' })
            } finally {
              // Remove the Compado ClickID after an order...
              localStorage.removeItem('jn:compado')
            }
          }

          try {
            const newOrder = this.nordfrost ?
              await client.b2bOrders.create({ ...order, slot: { from: this.checkout.nordfrost_week!.firstDay.toISOString().substring(0, 10), to: this.checkout.nordfrost_week!.lastDay.toISOString().substring(0, 10) } }) :
              await client.ordersV2.create({ ...order, slot_uuid: this.checkout.slot!.uuid })

            // GA Event: checkout step 4
            analyticsEvent('checkout', {
              currency: 'EUR',
              coupon: this.voucher.toUpperCase(),
              value: this.$centToEur(this.pre_order!.summary.total),
              items: analyticsOrderItems(this.cart.dishes),
              box_size: this.cart.box,
            })

            // Nordfrost order
            if (newOrder.uuid && this.nordfrost) return this.$router.push(`/business/thankyou#${newOrder.uuid}`)

            // Free order
            if (newOrder.uuid && newOrder.status === 'paid') return this.$router.push(`/thankyou#${newOrder.uuid}`)

            // Stripe order
            if (newOrder.uuid && stripe) return newOrder.uuid

            // Normal order
            else if (newOrder.uuid && this.checkout.payment) {
              checkoutBusy.value.push('')
              const mandate = this.selected_mandate
              const linksterURLQuery = sessionStorage.getItem('emid') ? `?emid=${sessionStorage.getItem('emid')}` : ''
              const thankyou_url = `${env.VITE_BASE_URL}thankyou#${newOrder.uuid}${linksterURLQuery}`
              const checkout_url = `${env.VITE_BASE_URL}${this.b2b ? 'business/' : ''}checkout${linksterURLQuery}`
              const options = mandate?.uuid ? {
                order_uuid: newOrder.uuid as any,
                redirect_url: thankyou_url,
                cancel_url: checkout_url,
                mandate_uuid: mandate.uuid,
              } as OneOffMandatePaymentOption : {
                order_uuid: newOrder.uuid as any,
                redirect_url: thankyou_url,
                cancel_url: checkout_url,
                method: this.checkout.payment,
                preferences: {
                  automatic: true,
                  save_details: true,
                  save_method: true,
                },
              } as OneOffPaymentOption
              try {
                const newPayment = await client.payments.createOneoff(options as OneOffMandatePaymentOption & OneOffPaymentOption)
                if (newPayment.checkout_url) window.location.assign(newPayment.checkout_url)
                if (newPayment.mandate_uuid || newPayment.method === 'invoice') this.$router.push(`/thankyou#${newOrder.uuid}`)
              } catch (err) {
                this.resolveError(err as Error)
              } finally {
                checkoutBusy.value.pop()
              }
            }
          } catch (err) {
            this.resolveError(err as Error)
          } finally {
            // Make sure we have the loading animation running until redirect
            setTimeout(() => checkoutBusy.value.pop(), 2000)
          }
        }
      },

      /* ========================================================================== *
       * Create Payment                                                            *
       * -------------------------------------------------------------------------- */
      async createPayment(order_uuid: string, confirmation_token?: string) {
        checkoutBusy.value.push('')
        const mandate = this.selected_mandate
        const thankyou_url = `${env.VITE_BASE_URL}thankyou#${order_uuid}`
        const checkout_url = `${env.VITE_BASE_URL}${this.b2b ? 'business/' : ''}checkout`
        const options = mandate?.uuid ? {
          order_uuid: order_uuid,
          redirect_url: thankyou_url,
          cancel_url: checkout_url,
          mandate_uuid: mandate.uuid,
        } as OneOffMandatePaymentOption : {
          confirmation_token: confirmation_token,
          order_uuid: order_uuid,
          redirect_url: thankyou_url,
          cancel_url: checkout_url,
          method: this.checkout.payment,
          preferences: {
            automatic: true,
            save_details: true,
            save_method: true,
          },
        } as OneOffPaymentOption
        try {
          const newPayment = await client.payments.createOneoff(options as OneOffMandatePaymentOption & OneOffPaymentOption)
          if (newPayment.client_secret) return newPayment // This is a payment created via stripe web element
          if (newPayment.checkout_url) window.location.assign(newPayment.checkout_url)
          if (newPayment.mandate_uuid || newPayment.method === 'invoice') this.$router.push(`/thankyou#${order_uuid}`)
        } catch (err) {
          this.resolveError(err as Error)
        } finally {
          checkoutBusy.value.pop()
        }
      },

      /* ========================================================================== *
       * Resolve Errors                                                             *
       * -------------------------------------------------------------------------- */
      async resolveError(err: Error | StripeError | null) {
        if (err && 'type' in err) {
          return await this.$createModal(alertModal as any, {
            title: err.type.replaceAll('_', ' ').toUpperCase(),
            description: err.message,
            locked: true,
          })
        } else if (err && err.status === 400) {
          if (err.details) this.error_reason = err.details.map((detail: Record<string, string>) => detail.reason as string)
          // Dish no longer available, refresh inventories
          if (this.error_reason.some((reason) => [ 'out_of_stock', 'not_sellable', 'not_found' ].includes(reason))) {
            await initializeInventories()
            return await this.$createModal(alertModal as any, {
              title: this.$t('checkout.not-available'),
              description: this.$t('checkout.dish-not-available'),
              url: this.b2b ? '/business/order' : '/order',
              locked: true,
            })
          // Delivery slot no longer available
          } else if (this.error_reason.some((reason) => [ 'booked_out', 'cutoff_passed', 'postcode_mismatch' ].includes(reason))) {
            this.accordion_active = 'slots'
            return this.slot_error = true
          // Wrong voucher
          } else if (this.error_reason.some((reason) => [ 'invalid' ].includes(reason))) {
            return await this.$createModal(alertModal as any, {
              title: this.$t('checkout.error-voucher-headline'),
              description: this.$t('checkout.error-voucher'),
              locked: true,
            })
          // Other errors
          } else {
            return await this.$createModal(alertModal as any, {
              title: this.$t('checkout.error-general-headline'),
              description: this.$t('checkout.error-general'),
              locked: true,
            })
          }
        } else {
          checkoutBusy.value.pop()
          return await this.$createModal(alertModal as any, {
            title: this.$t('checkout.error-general-headline'),
            description: this.$t('checkout.error-general'),
            locked: true,
          })
        }
      },
    },

  })
</script>

<style scoped lang="pcss">
  .summary {
    @apply w-full;

    /* Info Accordions in general */
    .info {
      @apply mb-2 pb-2 sm:mb-4 sm:pb-4 border-b border-gray-300 text-black;
      @apply cursor-pointer;

      /* Accordion Header */
      .header {
        @apply flex flex-nowrap relative;
        @apply w-full transition text-xs sm:text-sm sm:text-base;
        .scnd-line {
          @apply text-sm sm:text-base font-semibold;
        }
        .edit {
          @apply w-6 ml-2 self-center flex-shrink-0;
        }
        .carrier {
          @apply w-12 mr-2 md:mr-4 flex;
          svg {
            @apply self-center;
          }
        }
      }
      span {
        @apply text-black;
        @apply flex-grow;
      }

      /* Info Accordions: Slot and Payment */
      &.slot, &.payment {
        @apply flex relative min-h-11 sm:min-h-12 sm:min-h-10 flex-wrap;
        @apply opacity-100;
        @apply transition-all;
        .header {
          @apply absolute opacity-100 self-center;
        }
        :deep() .slot-list-wrapper, .payments, :deep() .subheading, :deep() .close {
          @apply block overflow-hidden relative z-10;
          @apply opacity-0 pt-0 pb-0 max-h-0;
          @apply transition-all bg-white duration-300 text-black;
        }
        &.active {
          @apply block;
          .payments {
            @apply overflow-visible;
          }
          &:not(.nordfrost) {
            .header {
              @apply opacity-0;
            }
          }
          :deep() .slot-list-wrapper, :deep() .subheading, :deep() .close {
            @apply opacity-100 max-h-[18rem];
            &.nordfrost {
              @apply max-h-[25rem];
            }
          }
          .payments {
            @apply opacity-100 max-h-96 md:max-h-[30rem] lg:max-h-96 overflow-scroll;
          }
          :deep() .slot-list-wrapper {
            @apply mobile-slot:overflow-scroll overflow-visible mobile-slot:-mx-1;
          }
        }
        &.nordfrost.payment {
          @apply pointer-events-none;
        }
      }

      /* Info Accordions: Slot */
      &.slot {
        &.with_urbify.active {
          @apply mobile-slot:border-none;
        }
        &.active:not(.at_start) {
          &:before {
            content: '';
            @apply hidden absolute w-full h-7 z-50 top-6;
            @apply bg-gradient-to-b from-white to-transparent;
            @apply mobile-slot:block;
          }
        }
        &.active.with_error:not(.at_end) {
          :deep() .invalid-msg:after {
            content: '';
            @apply hidden absolute w-full h-7 z-10 -top-8;
            @apply bg-gradient-to-t from-white to-transparent;
            @apply mobile-slot:block;
          }
        }
        &.active:not(.at_end):not(.with_urbify):not(.with_error):not(.nordfrost) {
          &:after {
            content: '';
            @apply hidden absolute w-full h-7 z-10 bottom-2;
            @apply bg-gradient-to-t from-white to-transparent;
            @apply mobile-slot:block;
          }
        }
        &.at_end, &.with_error, &.nordfrost {
          .delivery-extra-msg:before {
            @apply hidden;
          }
        }
      }

      /* Info Accordion: Payment */
      &.payment {
        @apply min-h-11 sm:min-h-9 sm:min-h-10;
      }

      /* Voucher */
      &.voucher {
        .header {
          @apply sm:max-h-6;
        }
        .voucher-input {
          @apply relative mr-1 sm:mr-2 w-full left-0 h-8 sm:h-10 top-1/2 transform -translate-y-1/2;
          @apply text-sm sm:text-base;
          @apply transition-all opacity-0 z-0;
          &.active {
            @apply opacity-100 z-20;
            & + .valid-voucher {
              @apply opacity-0;
            }
          }
        }
        .valid-voucher {
          @apply opacity-100 absolute self-center text-green text-base transition-opacity;
        }

        .title {
          @apply absolute text-sm sm:text-base self-center;
        }
        button {
          @apply text-sm sm:text-base h-8 sm:h-10 px-3 sm:px-6 top-1/2 transform -translate-y-1/2;
        }
        .check {
          @apply absolute top-1/2 transform -translate-y-1/2 rotate-45;
          @apply w-2 h-4 -mt-0.5 right-3;
          @apply border-b-2 border-r-2 border-green;
        }
        .invalid-msg {
          @apply sm:mt-3 -mb-1 sm:-mb-3 pl-10;
        }
      }
      &.active .title {
        @apply -z-1;
      }
    }

    /* Cart Summary */
    .cart {
      @apply mt-3 mb-4;
      .summary-line {
        @apply text-sm sm:text-base;
        @apply pb-1 sm:pb-1.5 mb-1 sm:mb-1.5 border-dashed border-b border-gray-300;
        &.short {
          @apply ml-10 md:w-2/3 md:ml-auto;
        }
        &.total {
          @apply text-base sm:text-lg font-semibold border-none;
        }
        &:last-child {
          @apply border-none -mb-4;
        }
        &.dish-list {
          @apply flex-wrap pb-0 max-h-0 overflow-hidden transition-all duration-500;
        }
        &.active {
          @apply max-h-[30rem] overflow-scroll pb-2;
        }
        &.detail, .detail {
          @apply flex;
          &.headline {
            @apply pb-1.5;
            @apply border-none cursor-pointer;
            span {
              @apply text-black self-center;
            }
            .arrow {
              @apply w-2.5 mt-0 transform rotate-90 inline-block ml-3 transition-all duration-500;
              &.active {
                @apply -mt-0.5 -rotate-90;
              }
            }
          }
          > span:first-of-type {
            @apply flex-grow;
          }
        }
        .dish {
          @apply flex mb-0.5;
          p {
            @apply w-7 mr-3 text-center;
          }
        }
        .payment-icons {
          @apply ml-auto mt-1 mr-0 flex w-48;
          svg {
            @apply ml-0.5 w-full;
          }
        }
      }
    }

    .agb-alert {
      @apply mt-2 text-xs text-right w-full;
      :deep() a {
        @apply text-blue font-semibold underline;
      }
    }
    .delivery-extra-msg {
      @apply w-full cursor-default relative;
      @apply pt-3;
      @apply flex flex-row justify-center;
      @apply mobile-slot:mt-3 mobile-slot:border-t border-gray-300;
      &:before {
        content: '';
        @apply hidden absolute w-full h-7 z-10 -top-10;
        @apply bg-gradient-to-t from-white to-transparent;
        @apply mobile-slot:block;
      }
    }
    .msg-box {
      @apply w-full mobile-slot:max-w-full max-w-[26rem] bg-greenUrbify/20;
      @apply flex flex-row justify-start items-center;
      @apply p-3;

      /* mobile < 599 */
      @apply mobile-sm:w-full;

      p {
        @apply mobile-sm:text-sm px-2 text-left;
      }

      .info-icon {
        @apply ml-3 mr-2 w-7 flex-shrink-0 self-center;
      }
    }
    .urbify-leaf {
      @apply mr-1 w-9 flex-shrink-0 fill-greenUrbify;
    }
  }
</style>
